<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<Search
			v-if="search"
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:codes="codes"
			:user="user"

			@reset="reset"
			@click="getSearch(1)"
		>
			<button
				slot="last"
				class="bg-identify pa-5-10 vertical-middle mr-10"
				@click="onHoliday"
			>등록</button>
		</Search>

		<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

			<div class="justify-space-between">

				<div class="line-height-200">총 <span class="font-weight-bold">{{ search.total_count | makeComma }}</span> 건</div>
				<select
					class="pa-5-10 mr-10 size-px-12"
					v-model="search.size"
					@change="getSearch(1)"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>공휴일명</th>
					<th>날짜</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in items"
					:key="'list_' + index"
				>
					<td>{{ item.idx}}</td>
					<td>{{ item.title }}</td>
					<td>{{ item.procDate }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="deleteHoliday(item)"
						>삭제</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

		<PopupLayer
			v-if="is_holiday"
		>
			<template
				v-slot:body
			>
				<div
					class="bg-white full-height ma-auto flex-column"
					style="width: 480px; min-height: 320px; max-height: 480px;"
				>
					<div class="pa-10-20 justify-space-between under-line">
						<h6>공휴일 등록</h6>
						<button
							@click="is_holiday = false"
						><v-icon>mdi-close-circle</v-icon></button>
					</div>
					<div
						class="full-height pa-20 flex-column justify-center"
					>
						<div class="justify-space-between ">
							<input
								v-model="item.title"
								class="input-box mr-10 text-center  pa-10"
								placeholder="공휴일 명"
								maxlength="10"
							/>
							<input
								v-model="item.date"
								class="input-box mr-10 text-center"
								placeholder="yyyy-mm-dd"
								maxlength="10"
							/>
						</div>

					</div>
					<div
						class="mt-auto pa-10"
					>
						<button
							@click="postHoliday"
							class="btn bg-identify pa-5-10"
						>저장</button>
					</div>
				</div>
			</template>
		</PopupLayer>

	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'Holiday',
	components: {PopupLayer, Search, Pagination},
	props: ['user', 'codes', 'Axios', 'rules', 'query']
	,data: function(){
		return {
			program: {
				name: '공휴일 목록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.query.page ? this.query.page : 1
				,size: this.query.size ? this.query.size : 10
				,search_type: this.query.search_type ? this.query.search_type : ''
				,keyword: this.query.keyword ? this.query.keyword : ''
			}
			,search_option: {
				search_type: [
					{ name: '휴일명', column: ''}
				]
			}
			,items: []
			,item: {
				date: ''
				, title: ''
			}
			, is_holiday: false
			, month: ''
			, day: ''
		}
	}
	,computed: {
		date: function(){
			let t = ''
			if(this.month && this.day){
				t = ('0' + this.month).split(-2)
				t += '-'
				t += ('0' + this.day).split(-2)
			}
			return t
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.Axios({
					method: 'get'
					, url: 'holiday'
					, data: this.search
				})

				if(result.success){
					this.items = result.data
					this.search.total_count = result.data.totalCount
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
			}
			this.getData()
		}
		, onHoliday: function(){
			this.is_holiday = true
		}
		, postHoliday: async function(){
			try{
				this.$emit('onLoading')
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.Axios({
					method: 'post'
					, url: 'holiday'
					, data: {
						idx: this.item.idx
						, date: this.item.date
						, title: this.item.title
					}
				})

				if(result.success){
					this.is_holiday = false
					await this.getData()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		, deleteHoliday: async function(item){
			try{
				this.$emit('onLoading')
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.Axios({
					method: 'delete'
					, url: 'holiday/_' + item.idx
					, data: {
					}
				})

				if(result.success){
					this.is_holiday = false
					await this.getData()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {

	}
}
</script>